<template>
  <div class="d-flex flex-column flex-root">
    <div class="
        login login-1
        d-flex
        flex-column flex-lg-row flex-column-fluid
        bg-white
      " :class="{
        'login-signin-on': this.state == 'signin',
        'login-signup-on': this.state == 'signup',
        'login-forgot-on': this.state == 'forgot',
      }" id="kt_login">
      <!--begin::Aside-->
      <div class="login-aside d-flex flex-column flex-row-auto" style="background-color: #f2c98a">
        <div class="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
          <a href="#" class="text-center mb-10">
            <img src="media/logos/logo-letter-1.png" class="max-h-70px" alt="" />
          </a>
          <h3 class="font-weight-bolder text-center font-size-h4 font-size-h1-lg" style="color: #986923">
            Mgoogo Pay
            <br />
          </h3>
        </div>
        <div class="
            aside-img
            d-flex
            flex-row-fluid
            bgi-no-repeat bgi-position-y-bottom bgi-position-x-center
          " :style="{ backgroundImage: `url(${backgroundImage})` }"></div>
      </div>
      <!--begin::Aside-->

      <!--begin::Content-->
      <div class="
          login-content
          flex-row-fluid
          d-flex
          flex-column
          justify-content-center
          position-relative
          overflow-hidden
          p-7
          mx-auto
        ">
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::登录-->
          <div class="login-form login-signin">
            <form class="form" novalidate="novalidate" id="kt_login_signin_form">
              <div class="pb-13 pt-lg-0 pt-5">
                <h2 class="
                    font-weight-bolder
                    text-dark
                    font-size-h4 font-size-h1-lg
                  ">
                  登录
                </h2>
              </div>

              <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-dark">用户名</label>
                <div id="example-input-group-1" label="" label-for="example-input-1">
                  <input class="
                      form-control form-control-solid
                      h-auto
                      py-7
                      px-6
                      rounded-lg
                    " type="text" name="username" ref="username" placeholder="请输入用户名" v-model="form.username" />
                </div>
              </div>

              <div class="form-group">
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5">密码</label>
                </div>
                <div id="example-input-group-2" label="" label-for="example-input-2">
                  <input class="
                      form-control form-control-solid
                      h-auto
                      py-7
                      px-6
                      rounded-lg
                    " type="password" name="password" ref="password" placeholder="请输入密码" v-model="form.password"
                    autocomplete="off" />
                </div>
              </div>

              <div class="form-group">
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5">谷歌验证码</label>
                </div>
                <div id="example-input-group-2" label="" label-for="example-input-2">
                  <input class="
                      form-control form-control-solid
                      h-auto
                      py-7
                      px-6
                      rounded-lg
                    " type="password" name="code" ref="code" placeholder="未绑定谷歌身份验证时，无需输入"
                    v-model="form.code" autocomplete="off" />
                </div>
              </div>

              <div class="pb-lg-0 pb-5">
                <button @click="onSubmitLogin()" ref="kt_login_signin_submit" class="
                    w-100
                    btn btn-primary
                    font-weight-bolder font-size-h6
                    px-15
                    py-4
                    my-3
                    mr-3
                  ">
                  登录
                </button>
              </div>
            </form>
          </div>
          <!--end::登录-->

          <!--begin::注册
          <div class="login-form login-signup">
            <form class="form" novalidate="novalidate" id="kt_login_signup_form">
              <b-row>
                <b-col cols="3">
                  <label class="form-group-label" style="font-size: 18px; font-weight: bolder !important">注册类型:</label>
                </b-col>
                <b-col cols="8">
                  <b-form-group label="">
                    <b-form-radio-group id="btn-radios-2" v-model="registerForm.userType" :options="typeOption" buttons
                      button-variant="outline-primary" size="lg" name="userType"></b-form-radio-group>
                  </b-form-group>
                </b-col>
              </b-row>

              <div class="form-group">
                <input class="
                    form-control form-control-solid
                    h-auto
                    py-5
                    px-4
                    rounded-lg
                    font-size-h6
                  " type="text" placeholder="用户名：手机号码/邮箱" name="userName" v-model="registerForm.userName" />
              </div>
              <div class="form-group">
                <input class="
                    form-control form-control-solid
                    h-auto
                    py-5
                    px-4
                    rounded-lg
                    font-size-h6
                  " type="text" placeholder="真实姓名/公司名称" name="corporateName" v-model="registerForm.corporateName" />
              </div>
              <div class="form-group">
                <input class="
                    form-control form-control-solid
                    h-auto
                    py-5
                    px-4
                    rounded-lg
                    font-size-h6
                  " type="text" placeholder="联系电话" name="phone" v-model="registerForm.phone" />
              </div>
              <div class="form-group">
                <input class="
                    form-control form-control-solid
                    h-auto
                    py-4
                    px-5
                    rounded-lg
                    font-size-h6
                  " type="password" placeholder="密码" name="password" v-model="registerForm.password" />
              </div>
              <div class="form-group">
                <input class="
                    form-control form-control-solid
                    h-auto
                    py-5
                    px-4
                    rounded-lg
                    font-size-h6
                  " type="password" placeholder="确认密码" name="cPassword" v-model="registerForm.cPassword" />
              </div>

              <div class="form-group">
                <label class="checkbox mb-0">
                  <input type="checkbox" name="agree" />
                  <span class="mr-2"></span>
                  提交注册后，需等待管理员审核通过方可使用
                </label>
              </div>
              <div class="form-group d-flex flex-wrap pb-lg-0 pb-3">
                <button ref="kt_login_signup_submit" class="
                    btn btn-primary
                    font-weight-bolder font-size-h6
                    px-8
                    py-4
                    my-3
                    mr-4
                  " style="width: 150px" @click="onSubmitRegister()">
                  提交
                </button>
                <button type="button" id="kt_login_signup_cancel" class="
                    btn btn-light-primary
                    font-weight-bolder font-size-h6
                    px-8
                    py-4
                    my-3
                  " @click="showForm('signin')">
                  <span class="svg-icon svg-icon-primary svg-icon-2x">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                      height="24px" viewBox="0 0 24 24" version="1.1">
                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <polygon points="0 0 24 0 24 24 0 24" />
                        <path
                          d="M4.7071045,12.7071045 C4.3165802,13.0976288 3.68341522,13.0976288 3.29289093,12.7071045 C2.90236664,12.3165802 2.90236664,11.6834152 3.29289093,11.2928909 L9.29289093,5.29289093 C9.67146987,4.914312 10.2810563,4.90106637 10.6757223,5.26284357 L16.6757223,10.7628436 C17.0828413,11.136036 17.1103443,11.7686034 16.7371519,12.1757223 C16.3639594,12.5828413 15.7313921,12.6103443 15.3242731,12.2371519 L10.0300735,7.38413553 L4.7071045,12.7071045 Z"
                          fill="#000000" fill-rule="nonzero"
                          transform="translate(10.000001, 8.999997) scale(-1, -1) rotate(90.000000) translate(-10.000001, -8.999997) " />
                        <path
                          d="M20,8 C20.5522847,8 21,8.44771525 21,9 C21,9.55228475 20.5522847,10 20,10 L13.5,10 C12.9477153,10 12.5,10.4477153 12.5,11 L12.5,21.0415946 C12.5,21.5938793 12.0522847,22.0415946 11.5,22.0415946 C10.9477153,22.0415946 10.5,21.5938793 10.5,21.0415946 L10.5,11 C10.5,9.34314575 11.8431458,8 13.5,8 L20,8 Z"
                          fill="#000000" fill-rule="nonzero" opacity="0.3"
                          transform="translate(15.750000, 15.020797) scale(-1, 1) translate(-15.750000, -15.020797) " />
                      </g>
                    </svg>
                  </span>
                  已有账号?
                </button>
              </div>
            </form>
          </div>
          -->
        </div>
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState } from "vuex";
import { LOGIN, LOGOUT, REGISTER } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";

export default {
  name: "login-1",
  data() {
    return {
      state: "signin",
      form: {
        username: "",
        password: "",
        code: ""
      },
      registerForm: {
        userType: 2,
        userName: "",
        corporateName: "",
        phone: "",
        password: "",
        cPassword: "",
      },
      typeOption: [
        { text: "会员注册", value: 2 },
        { text: "供货商注册", value: 3 },
      ],
    };
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    ...mapGetters(["currentUser"]),

    backgroundImage() {
      return (
        process.env.BASE_URL + "media/svg/illustrations/login-visual-1.svg"
      );
    },
  },
  mounted() {
    const signin_form = KTUtil.getById("kt_login_signin_form");
    // const signup_form = KTUtil.getById("kt_login_signup_form");

    //登录表单校验
    this.fv = formValidation(signin_form, {
      fields: {
        username: {
          validators: {
            notEmpty: {
              message: "用户名不能为空",
            },
          },
        },
        password: {
          validators: {
            notEmpty: {
              message: "密码不能为空",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });

    // //注册表单校验
    // this.fv1 = formValidation(signup_form, {
    //   fields: {
    //     userType: {
    //       validators: {
    //         notEmpty: {
    //           message: "请选择注册类型!",
    //         },
    //       },
    //     },
    //     userName: {
    //       validators: {
    //         notEmpty: {
    //           message: "用户名不能为空!",
    //         },
    //         remote: {
    //           message: "此用户名已存在,不可用!",
    //           method: "POST",
    //           url: "/api/user/exist",
    //         },
    //       },
    //     },
    //     corporateName: {
    //       validators: {
    //         notEmpty: {
    //           message: "真实姓名或公司不能为空!",
    //         },
    //       },
    //     },
    //     phone: {
    //       validators: {
    //         notEmpty: {
    //           message: "电话号码不能为空!",
    //         },
    //       },
    //     },
    //     password: {
    //       validators: {
    //         notEmpty: {
    //           message: "密码不能为空！",
    //         },
    //       },
    //     },
    //     cPassword: {
    //       validators: {
    //         notEmpty: {
    //           message: "请再次确认密码！",
    //         },
    //         identical: {
    //           compare: function () {
    //             return signup_form.querySelector('[name="password"]').value;
    //           },
    //           message: "两次密码不一致！",
    //         },
    //       },
    //     },
    //   },
    //   plugins: {
    //     trigger: new Trigger(),
    //     submitButton: new SubmitButton(),
    //     bootstrap: new Bootstrap(),
    //   },
    // });
  },
  methods: {
    showForm(form) {
      this.state = form;
      var form_name = "kt_login_" + form + "_form";
      KTUtil.animateClass(
        KTUtil.getById(form_name), "animate__animated animate__backInUp"
      );
    },

    //提交登录
    onSubmitLogin() {
      let that = this;
      this.fv.validate().then(function (status) {
        if (status === "Valid") {
          let username = that.form.username;
          let password = that.form.password;
          let code = that.form.code;


          // clear existing errors
          that.$store.dispatch(LOGOUT);

          // set spinner to submit button
          const submitButton = that.$refs["kt_login_signin_submit"];
          submitButton.classList.add(
            "spinner",
            "spinner-light",
            "spinner-right"
          );

          // send login request
          that.$store
            .dispatch(LOGIN, { username, password,code })
            .then((data) => {
              if (data.success) {
                that.$router.push("/dashboard");
              } else {
                Swal.fire({
                  title: "登录失败！",
                  text: data.message,
                  icon: "warning",
                  confirmButtonClass: "btn btn-success",
                  heightAuto: false,
                });
              }
              submitButton.classList.remove(
                "spinner",
                "spinner-light",
                "spinner-right"
              );
            })
            .catch(({ err }) => {
              submitButton.classList.remove(
                "spinner",
                "spinner-light",
                "spinner-right"
              );
            });
        }
      });
    },

    //提交注册
    // onSubmitRegister() {
    //   let that = this;
    //   this.fv1.validate().then(function (status) {
    //     if (status === "Valid") {
    //       // set spinner to submit button
    //       const submitButton = that.$refs["kt_login_signup_submit"];
    //       submitButton.classList.add(
    //         "spinner",
    //         "spinner-light",
    //         "spinner-right"
    //       );
    //       // send register request
    //       that.$store
    //         .dispatch(REGISTER, that.registerForm)
    //         .then((data) => {
    //           if (data.success) {
    //             that.showForm("signin");
    //             Swal.fire({
    //               title: "注册成功",
    //               text: "请等待管理员审核成功后，账号方可登录!",
    //               icon: "success",
    //               confirmButtonClass: "btn btn-success",
    //               heightAuto: false,
    //             });
    //           } else {
    //             Swal.fire({
    //               title: "注册失败！",
    //               text: data.message,
    //               icon: "warning",
    //               confirmButtonClass: "btn btn-success",
    //               heightAuto: false,
    //               timer: 3000,
    //             });
    //           }
    //         })
    //         .catch(({ err }) => {
    //           console.error(err);
    //           submitButton.classList.remove(
    //             "spinner",
    //             "spinner-light",
    //             "spinner-right"
    //           );
    //         });
    //     }
    //   });
    // },
  },
};
</script>
